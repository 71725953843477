/* Add fade effect for tab panel switching */
.panel {
  transition-property: opacity, transform;
  transition: 0.5s ease-out;
}
@starting-style {
  .panel {
    opacity: 0.75;
  }
}
@media (prefers-reduced-motion: no-preference) {
  @starting-style {
    .panel {
      opacity: 0.5;
      transform: translateY(1rem);
    }
  }
}
