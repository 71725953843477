.root {
  @apply absolute;
  @apply inset-0;
}

.previous,
.next {
  @apply absolute;
  @apply hidden;
  @apply items-center;
  @apply top-0;
  @apply bottom-0;
  @apply transition-all;
  @apply transform-gpu;
  @apply duration-200;
  @apply z-10;
  @apply w-36;
  /**
   * Since this block is only used for visual purposes (gradient overlay) we
   * want to make sure that click events on this element get passed through.
   */
  @apply pointer-events-none;
}

.previous {
  @apply left-0;
  @apply bg-gradient-to-r;
  @apply from-pageBackground;
  @apply to-transparent;
  @apply justify-start;
  @apply pl-6;
  @apply -translate-x-6;
  @apply opacity-0;
}

.next {
  @apply right-0;
  @apply bg-gradient-to-r;
  @apply from-transparent;
  @apply to-pageBackground;
  @apply pr-6;
  @apply justify-end;
  @apply translate-x-6;
  @apply opacity-0;
}

.button {
  /** Here we negate the `pointer-events-none` applied to the parent element */
  @apply pointer-events-auto;
}

/**
 * Make sure to only show hover animations on devices where the primary
 * input device can conveniently hover over elements.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover
 */
@media (hover: hover) {
  .previous,
  .next {
    @apply flex;
  }
  :global(.slider:hover) .previous,
  :global(.slider:hover) .next {
    @apply opacity-100;
    @apply translate-x-0;
  }
}
